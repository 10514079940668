:root{
  /*--primary-color: #A08BE3;*/
  --primary-color: #E9B44C;
  /*--secondary-color: #7A6F9B;*/
  --secondary-color: #1C110A;
  /*--tertiary-color: #D4CDF4;*/
  --tertiary-color: #E9B44C;
  /*--quartenary-color: #585481;*/
  --quartenary-color: #1C110A;
  font-size: 16px;
}

*{
  font-family: 'Courier New', Courier, monospace;
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.6rem;
}

html, body {
  height: 100%;
  box-sizing: border-box;
}
#root{
  border: 10px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.App{
  width: 100%;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 20px;
  border-bottom: 1px solid var(--tertiary-color);
  min-height: 100vh;
  height: 100%;
}

.section__content{
  width: 100%;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section__title{
  top: 5vh;
  font-size: 2rem;
  margin: 20px 0;
  color: var(--secondary-color);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
}

/* Media-Queries */
@media screen and (min-width: 768px) {
  .section__content{
     max-width: 1000px;
     flex-direction: row;
  }
}

