.footerSection{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    text-align: center;
    min-height: fit-content;
    /**width: 100%;*/
    border: none;
}

.footerSection__title{
    color: #FFF;
    font-size: 1.2rem;
}